<template>
    <v-dialog
        persistent
        v-model="uploadDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
    >
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
                <v-stepper v-model="uploadWizard">
                    <v-stepper-header>
                        <v-stepper-step
                            :complete="uploadWizard > 1"
                            step="1"
                        >
                            {{ $t('message.uploadFile') }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step
                            :complete="uploadWizard > 2"
                            step="2"
                        >
                            {{ $t('message.checkBundles') }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step
                            :complete="uploadWizard > 3"
                            step="3"
                        >
                            {{ $t('message.addToList') }}
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <div class="d-flex flex-row pb-3">
                                <v-file-input
                                        :placeholder="$t('message.selectExcelFile')"
                                        :error-messages="manualErrorState"
                                        :rules="excelFile != null ? [...validationRules.validExcelFiles] : [true]"
                                        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                        dense
                                        hide-details="auto"
                                        solo
                                        v-model="excelFile"
                                        @change="checkFile"
                                />
                                <v-btn class="ml-2"
                                       :disabled="excelData == [] && excelFile == null"
                                       :loading="uploadingFile"
                                       @click="parseExcelFile"
                                >
                                    {{ this.excelData.length > 0 ? $t('message.uploaded') : $t('message.upload') }}
                                    <v-icon v-if="this.excelData.length > 0" class="ml-2 success--text">mdi-check</v-icon>
                                </v-btn>
                            </div>
                            <div class="d-flex flex-row pb-3">
                                <v-btn color="success" @click="uploadWizard = 2" :disabled="excelData.length == 0">{{ $t('message.continue') }}</v-btn>
                                <v-btn class="ml-2" @click="uploadDialog = false">{{ $t('message.cancel') }}</v-btn>
                            </div>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                            <v-simple-table
                                :height="'400px'"
                                class="pb-3"
                                dense
                                fixed-header
                                style="width: 1500px !important;"
                            >
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="blue-grey lighten-4 pl-1">#</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[0].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[1].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[2].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[3].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[4].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[5].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[6].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[7].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[8].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[9].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[10].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[11].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[12].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[13].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[14].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[15].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[16].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[17].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[18].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[19].text }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(bundle,index) in excelData" v-bind:key="index">
                                            <td>{{ index + 1 }}</td>
                                            <td :class="bundle.bundleNo.error ? 'red--text' : ''">{{ bundle.bundleNo.text }}</td>
                                            <td :class="bundle.product.error ? 'red--text' : ''">{{ bundle.product.text }}</td>
                                            <td :class="bundle.species.error ? 'red--text' : ''">{{ bundle.species.text }}</td>
                                            <td :class="bundle.grade.error ? 'red--text' : ''">{{ bundle.grade.text }}</td>
                                            <td :class="bundle.spec.error ? 'red--text' : ''">{{ bundle.spec.text }}</td>
                                            <td :class="bundle.mc.error ? 'red--text' : ''">{{ bundle.mc.text }}</td>
                                            <td :class="bundle.certification.error ? 'red--text' : ''">{{ bundle.certification.text }}</td>
                                            <td :class="bundle.thickness.error ? 'red--text' : ''">{{ bundle.thickness.text }}</td>
                                            <td :class="bundle.thicknessUnit.error ? 'red--text' : ''">{{ bundle.thicknessUnit.text }}</td>
                                            <td :class="bundle.width.error ? 'red--text' : ''">{{ bundle.width.text }}</td>
                                            <td :class="bundle.widthUnit.error ? 'red--text' : ''">{{ bundle.widthUnit.text }}</td>
                                            <td :class="bundle.length.error ? 'red--text' : ''">{{ bundle.length.text }}</td>
                                            <td :class="bundle.lengthUnit.error ? 'red--text' : ''">{{ bundle.lengthUnit.text }}</td>
                                            <td :class="bundle.pcs.error ? 'red--text' : ''">{{ bundle.pcs.text }}</td>
                                            <td :class="bundle.quantity.error ? 'red--text' : ''">{{ bundle.quantity.text }}</td>
                                            <td :class="bundle.quantityUnit.error ? 'red--text' : ''">{{ bundle.quantityUnit.text }}</td>
                                            <td :class="bundle.buyingPrice.error ? 'red--text' : ''">{{ bundle.buyingPrice.text }}</td>
                                            <td :class="bundle.freightCost.error ? 'red--text' : ''">{{ bundle.freightCost.text }}</td>
                                            <td :class="bundle.whCost.error ? 'red--text' : ''">{{ bundle.whCost.text }}</td>
                                            <td :class="bundle.salesPrice.error ? 'red--text' : ''">{{ bundle.salesPrice.text }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <div class="d-flex flex-row pb-3">
                                <v-btn color="success" @click="uploadWizard = 3">{{ $t('message.continue') }}</v-btn>
                                <v-btn class="ml-2" @click="uploadDialog = false">{{ $t('message.cancel') }}</v-btn>
                            </div>
                        </v-stepper-content>
                        <v-stepper-content step="3">
                            <div class="pb-3 font-weight-bold">{{ $t('message.add') + " " + excelData.length + " " + $t('message.bundlesToList') + '.' }}</div>
                            <div class="d-flex flex-row pb-3 justify-space-between">
                                <v-btn @click="addToBundleList" :loading="addingToBundleList" color="success">
                                    {{ this.addedToBundleList ? $t('message.added') : $t('message.add') }}
                                    <v-icon v-if="this.addedToBundleList" class="ml-2 white--text">mdi-check</v-icon>
                                </v-btn>
                                <v-btn @click="uploadDialog = false">{{ this.addedToBundleList ? $t('message.close') : $t('message.cancel') }}</v-btn>
                            </div>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {uofm} from "@/store/modules/appic/constants";
import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";

const ExcelJS = require('exceljs')

export default {
    name: "BundleExcelUpload",
    props: ['stockOrderId','dialog','updateMode','dialog-closed','upload-done'],
    data(){
        return {
            addedToBundleList: false,
            addingToBundleList: false,
            dialogs: {
                error: false,
                error_message: ""
            },
            excelData: [],
            excelFile: null,
            headers: [
                { id: 1, text: this.$t('message.bundleNo') },
                { id: 2, text: this.$t('message.product') },
                { id: 3, text: this.$t('message.species') },
                { id: 4, text: this.$t('message.grade') },
                { id: 5, text: this.$t('message.spec') },
                { id: 6, text: this.$t('message.mc') },
                { id: 7, text: this.$t('message.fsc') },
                { id: 8, text: this.$t('message.thickness') },
                { id: 9, text: this.$t('message.unit') },
                { id: 10, text: this.$t('message.width') },
                { id: 11, text: this.$t('message.unit') },
                { id: 12, text: this.$t('message.length') },
                { id: 13, text: this.$t('message.unit') },
                { id: 14, text: this.$t('message.pcs') },
                { id: 15, text: this.$t('message.quantity') },
                { id: 16, text: this.$t('message.unit') },
                { id: 17, text: this.$t('message.buyingPrice') },
                { id: 18, text: this.$t('message.freightCost') },
                { id: 19, text: this.$t('message.whCost') },
                { id: 20, text: this.$t('message.salesPrice') },
            ],
            loading: {
                add: false,
                get: false,
                save: false
            },
            manualErrorState: '',
            rules: {
                file: [
                    v => !!v || this.$t('message.required'),
                    v => (v && v.size > 0) || this.$t('message.required'),
                ],
            },
            upload_dialog: false,
            uploadingFile: false,
            uploadWizard: 1
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('stock',{
            Bundles: 'Bundles'
        }, 'statePrefix'),
        ...mapGetters('certification',{
            allCertifications: 'allCertifications'
        }),
        ...mapGetters('grade',{
            allGrades: 'allGrades',
            findGradeById: 'findGradeById'
        }),
        ...mapGetters('mc', {
            allMoistureContent: 'allMoistureContent'
        }),
        ...mapGetters('productgroup',{
            allProductGroups: 'allProductGroups'
        }),
        ...mapGetters('species', {
            allSpecies: 'allSpecies',
            findSpeciesById: 'findSpeciesById'
        }),
        ...mapGetters('spec',{
            allSpecs: 'allSpecs',
            allCleanedSpecs: 'allCleanedSpecs',
            findSpecById: 'findSpecById'
        }),
        ...mapGetters('stock',{
            defaultBundle: 'defaultBundle'
        }),
        ...mapGetters([
            'currencies',
            'offices',
            'uofmDimensions',
            'uofmQty',
            'validationRules'
        ]),
        uploadDialog: {
            get() {
                return this.upload_dialog;
            },
            set(value){
                this.upload_dialog = value
                if(value == false) {
                    this.uploadWizard = 1
                    this.excelFile = null
                    this.excelData = []
                    this.$emit('dialog-closed')
                }
            }
        },
    },
    methods: {
        ...mapActions('certification',{
            getAllCertifications: 'getAllCertifications'
        }),
        ...mapActions('grade', {
            getAllGrades: 'getAllGrades'
        }),
        ...mapActions('mc', {
            getAllMoistureContent: 'getAllMoistureContent'
        }),
        ...mapActions('productgroup', {
            getAllProductGroups: 'getAllProductGroups'
        }),
        ...mapActions('size', {
            getAllSizes: 'getAllSizes'
        }),
        ...mapActions('species', {
            getAllSpecies: 'getAllSpecies'
        }),
        ...mapActions('spec', {
            getAllSpecs: 'getAllSpecs',
            getAllCleanedSpecs: 'getAllCleanedSpecs'
        }),
        addToBundleList() {
            this.addingToBundleList = true
            let i = 1
            this.excelData.forEach(row => {
                let bundle = _.cloneDeep(this.defaultBundle)
                bundle.index = i
                bundle.Stock.bundleno = row.bundleNo.value
                bundle.Stock.certification_id = row.certification.value
                bundle.Stock.costprice = row.buyingPrice.value
                bundle.Stock.forwardingcharge = row.freightCost.value
                bundle.Stock.fsc = row.certification.text
                bundle.Stock.grade_id = row.grade.value
                bundle.Stock.lmeasurement_id = row.lengthUnit.value
                bundle.Stock.mc_id = row.mc.value
                // bundle.Stock.measurement_id = row.quantityUnit.values
                bundle.Stock.piece = row.pcs.value
                bundle.Stock.productgroup_id = row.product.value
                bundle.Stock.salesdescription = row.spec.value
                bundle.Stock.sellingprice = row.salesPrice.value
                bundle.Stock.smeasurement_id = row.thicknessUnit.value
                bundle.Stock.soldmeasurement_id = row.quantityUnit.value
                bundle.Stock.species_id = row.species.value
                bundle.Stock.thick = row.thickness.value
                bundle.Stock.volume = row.quantity.value
                bundle.Stock.warehousecost = row.whCost.value
                bundle.Stock.width = row.width.value
                bundle.Stock.wmeasurement_id = row.widthUnit.value
                this.Bundles.unshift(bundle)
                i++
            })
            if(i == this.excelData.length + 1){
                this.addingToBundleList = false
                this.addedToBundleList = true
            } else {
                this.addingToBundleList = false
                this.$toast.error(this.$t('message.bundlesCouldNotBeAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        checkFile(fileObj) {
            if(fileObj) {
                const allowedExtensions = this.validationRules.validExcelFileExtensions
                if (fileObj.type == "" || allowedExtensions.exec(fileObj.name) == null) {
                    this.manualErrorState = 'XLS or XLSX files only.'
                } else {
                    this.manualErrorState = ''
                }
            }
        },
        continueWizard(nextStep) {
            switch(nextStep){
                case 1:
                    break
                case 2:
                    break
                case 3:
                    break
            }
        },
        async parseExcelFile() {
            let _this = this
            this.uploadingFile = true
            let reader = new FileReader()
            reader.readAsArrayBuffer(this.excelFile)
            reader.onloadend = function () {
                let file = reader.result;
                const workbook = new ExcelJS.Workbook();
                try {
                    workbook.xlsx.load(file).then((workbook) => {
                        const worksheet = workbook.worksheets[0]
                        worksheet.eachRow(function (row, rowNumber) {
                            if(rowNumber > 1) {
                                let bundle = {}
                                bundle['bundleNo'] = {
                                    value: row.getCell(2).value,
                                    text: row.getCell(2).value ? row.getCell(2).value : _this.$t('message.empty'),
                                    error: row.getCell(2).value ? false : true,
                                }

                                let product = _this.allProductGroups.find(p => p.ProductGroup.title == row.getCell(3).value)
                                bundle['product'] = {
                                    value: product ? product.ProductGroup.id : null,
                                    text: product ? product.ProductGroup.title : _this.$t('message.empty'),
                                    error: product ? false : true
                                }

                                let species = _this.allSpecies.find(s => s.Species.title == row.getCell(4).value)
                                bundle['species'] = {
                                    value: species ? species.Species.id : null,
                                    text: species ? species.Species.title : _this.$t('message.empty'),
                                    error: species ? false : true
                                }

                                let grade = _this.allGrades.find(g => g.Grade.title == row.getCell(5).value)
                                bundle['grade'] = {
                                    value: grade ? grade.Grade.id : null,
                                    text: grade ? grade.Grade.title : _this.$t('message.empty'),
                                    error: grade ? false : true
                                }

                                bundle['spec'] = {
                                    value: row.getCell(6).value,
                                    text: row.getCell(6).value ? row.getCell(6).value : _this.$t('message.empty'),
                                    error: row.getCell(6).value ? false : true,
                                }

                                let mc = _this.allMoistureContent.find(c => c.Mc.title == row.getCell(7).value)
                                bundle['mc'] = {
                                    value: mc ? mc.Mc.id : null,
                                    text: mc ? mc.Mc.title : _this.$t('message.empty'),
                                    error: mc ? false : true
                                }

                                let certification = _this.allCertifications.find(c => c.Certification.title == row.getCell(8).value)
                                bundle['certification'] = {
                                    value: certification ? certification.Certification.id : null,
                                    text: certification ? certification.Certification.title : _this.$t('message.empty'),
                                    error: certification ? false : true
                                }

                                bundle['thickness'] = {
                                    value: row.getCell(10).value,
                                    text: row.getCell(10).value ? row.getCell(10).value : _this.$t('message.empty'),
                                    error: row.getCell(10).value ? false : true,
                                }

                                let thicknessUnit = _this.uofmDimensions.find(u => u.Measurement.title == row.getCell(11).value)
                                bundle['thicknessUnit'] = {
                                    value: thicknessUnit ? thicknessUnit.Measurement.id : null,
                                    text: thicknessUnit ? thicknessUnit.Measurement.title : _this.$t('message.empty'),
                                    error: thicknessUnit ? false : true
                                }

                                bundle['width'] = {
                                    value: row.getCell(12).value,
                                    text: row.getCell(12).value ? row.getCell(12).value : _this.$t('message.empty'),
                                    error: row.getCell(12).value ? false : true,
                                }

                                let widthUnit = _this.uofmDimensions.find(u => u.Measurement.title == row.getCell(13).value)
                                bundle['widthUnit'] = {
                                    value: widthUnit ? widthUnit.Measurement.id : null,
                                    text: widthUnit ? widthUnit.Measurement.title : _this.$t('message.empty'),
                                    error: widthUnit ? false : true
                                }

                                bundle['length'] = {
                                    value: row.getCell(14).value,
                                    text: row.getCell(14).value ? row.getCell(14).value : _this.$t('message.empty'),
                                    error: row.getCell(14).value ? false : true,
                                }

                                let lengthUnit = _this.uofmDimensions.find(u => u.Measurement.title == row.getCell(15).value)
                                bundle['lengthUnit'] = {
                                    value: lengthUnit ? lengthUnit.Measurement.id : null,
                                    text: lengthUnit ? lengthUnit.Measurement.title : _this.$t('message.empty'),
                                    error: lengthUnit ? false : true
                                }

                                bundle['pcs'] = {
                                    value: row.getCell(16).value,
                                    text: row.getCell(16).value ? row.getCell(16).value : _this.$t('message.empty'),
                                    error: row.getCell(16).value ? false : true,
                                }

                                bundle['quantity'] = {
                                    value: row.getCell(17).value,
                                    text: row.getCell(17).value ? row.getCell(17).value : _this.$t('message.empty'),
                                    error: row.getCell(17).value ? false : true,
                                }

                                let quantityUnit = _this.uofmQty.find(u => u.Measurement.title == row.getCell(18).value)
                                bundle['quantityUnit'] = {
                                    value: quantityUnit ? quantityUnit.Measurement.id : null,
                                    text: quantityUnit ? quantityUnit.Measurement.title : _this.$t('message.empty'),
                                    error: quantityUnit ? false : true
                                }

                                bundle['buyingPrice'] = {
                                    value: row.getCell(19).value,
                                    text: row.getCell(19).value ? row.getCell(19).value : _this.$t('message.empty'),
                                    error: row.getCell(19).value ? false : true,
                                }

                                bundle['freightCost'] = {
                                    value: row.getCell(20).value,
                                    text: row.getCell(20).value ? row.getCell(20).value : _this.$t('message.empty'),
                                    error: row.getCell(20).value ? false : true,
                                }

                                bundle['whCost'] = {
                                    value: row.getCell(21).value,
                                    text: row.getCell(21).value ? row.getCell(21).value : _this.$t('message.empty'),
                                    error: row.getCell(21).value ? false : true,
                                }

                                bundle['salesPrice'] = {
                                    value: row.getCell(22).value,
                                    text: row.getCell(22).value ? row.getCell(22).value : _this.$t('message.empty'),
                                    error: row.getCell(22).value ? false : true,
                                }
                                if(row.getCell(2).value != null  && row.getCell(2).value != '') {
                                    _this.excelData.push(bundle)
                                }
                            }
                        });
                        _this.uploadingFile = false
                    })
                } catch (err){
                    _this.$toast.error(err,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    _this.uploadingFile = false
                }
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == true){

            } else {

            }
            this.upload_dialog = value
        }
    },
    created() {
        if(this.allSpecies.length == 0) this.getAllSpecies()
        if(this.allGrades.length == 0) this.getAllGrades()
        if(this.allCertifications.length == 0) this.getAllCertifications()
        if(this.allMoistureContent.length == 0) this.getAllMoistureContent()
        if(this.allProductGroups.length == 0) this.getAllProductGroups()
        if(this.allCleanedSpecs.length == 0) this.getAllCleanedSpecs()
    }
}
</script>

<style scoped>
::v-deep .v-dialog {
    width: unset;
    max-width: unset !important;
}

.v-data-table th {
    font-size: 1em !important;
}

.v-data-table td {
    font-size: 1em !important;
}
</style>